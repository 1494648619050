@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://p.typekit.net/p.css?s=1&k=acm6eje&ht=tk&f=10294.10300.10302.14032.14033.14038.14039&a=53911303&app=typekit&e=css');

@font-face {
  font-family: 'brandon-grotesque';
  src:
    url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src:
    url('https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src:
    url('https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src:
    url('https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src:
    url('https://use.typekit.net/af/1281a1/000000000000000077359ded/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/1281a1/000000000000000077359ded/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/1281a1/000000000000000077359ded/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src:
    url('https://use.typekit.net/af/d03e48/000000000000000077359df2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d03e48/000000000000000077359df2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d03e48/000000000000000077359df2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src:
    url('https://use.typekit.net/af/b59a99/000000000000000077359df3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b59a99/000000000000000077359df3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b59a99/000000000000000077359df3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src:
    url('https://use.typekit.net/af/63eba7/00000000000000007753ca4f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/63eba7/00000000000000007753ca4f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/63eba7/00000000000000007753ca4f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src:
    url('https://use.typekit.net/af/f7a8b2/00000000000000007753ca28/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/f7a8b2/00000000000000007753ca28/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/f7a8b2/00000000000000007753ca28/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src:
    url('https://use.typekit.net/af/2b93b6/00000000000000007753ca37/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/2b93b6/00000000000000007753ca37/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/2b93b6/00000000000000007753ca37/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src:
    url('https://use.typekit.net/af/ae6138/00000000000000007753ca27/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/ae6138/00000000000000007753ca27/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/ae6138/00000000000000007753ca27/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src:
    url('https://use.typekit.net/af/23491a/00000000000000007753ca34/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/23491a/00000000000000007753ca34/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/23491a/00000000000000007753ca34/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src:
    url('https://use.typekit.net/af/b9ba1c/00000000000000007753ca39/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b9ba1c/00000000000000007753ca39/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b9ba1c/00000000000000007753ca39/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src:
    url('https://use.typekit.net/af/46adfc/00000000000000007753ca29/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/46adfc/00000000000000007753ca29/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/46adfc/00000000000000007753ca29/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

body {
  @apply bg-secondary;
  margin: 0 !important;
  /* font-family: brandon-grotesque, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  scroll-behavior: smooth !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Applying font families */
@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }

  body {
    @apply font-body;
  }
}

/**
  * THIRD-PARTY OVERRIDES
  */

/* Homebox */
#homeboxComparison {
  border-radius: 8px !important;
  overflow: hidden !important;
}

#homeboxComparison .homebox-ServiceBlock__block {
  box-shadow: none !important;
  margin-bottom: 0 !important;
}

#homeboxComparison .button--primary {
  background-color: #000000 !important;
  border-radius: 10px !important;
}

@media (min-width: 900px) {
  #homeboxComparison div:has(> input[value='Get Started »']) {
    background-position: right;
    background-size: contain;
    padding-bottom: 0;
  }
}

/* Toast */
.Toastify__toast-theme--colored.Toastify__toast--error {
  @apply bg-error;
}

/**
  * ANIMATIONS
  */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animateFadeIn {
  animation: fadeIn 200ms;
}

@keyframes spinReverse {
  to {
    transform: rotate(-360deg);
  }
}
.animateSpin {
  animation: spin 3s linear infinite;
}

.animateSpin-fast {
  animation: spin 1.5s linear infinite;
}

.animateSpinReverse {
  animation: spinReverse 3s linear infinite;
}

@keyframes popIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-popIn {
  animation: popIn 0.4s ease-in-out forwards;
}

@keyframes bounceInShallow {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}

.animateBounceInShallow {
  animation: bounceInShallow 0.6s ease-out;
}

@keyframes bounceIn {
  0% {
    transform: translateY(150px);
    opacity: 0;
  }
  60% {
    transform: translateY(-50px);
    opacity: 1;
  }
  80% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-10px);
  }
}

.loading-dots .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: currentColor;
  border-radius: 50%;
  margin-left: 4px;
  animation: wave 1s infinite;
}

.loading-dots .dot:nth-child(1) {
  animation-delay: 0s;
}

.loading-dots .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots .dot:nth-child(3) {
  animation-delay: 0.4s;
}

/**
  * TRUSTPILOT STARS
  */

.stars-4-5 {
  width: calc(125px);
  overflow: hidden;
  clip-path: inset(0 10% 0 0); /* Hide right half */
}

.stars-4 {
  width: calc(125px);
  overflow: hidden;
  clip-path: inset(0 20% 0 0); /* Hide right half */
}

.stars-3-5 {
  width: calc(125px);
  overflow: hidden;
  clip-path: inset(0 30% 0 0); /* Hide right half */
}

.stars-3 {
  width: calc(125px);
  overflow: hidden;
  clip-path: inset(0 40% 0 0); /* Hide right half */
}

.stars-2-5 {
  width: calc(125px);
  overflow: hidden;
  clip-path: inset(0 50% 0 0); /* Hide right half */
}

.stars-2 {
  width: calc(125px);
  overflow: hidden;
  clip-path: inset(0 60% 0 0); /* Hide right half */
}

.stars-1-5 {
  width: calc(125px);
  overflow: hidden;
  clip-path: inset(0 70% 0 0); /* Hide right half */
}

.stars-1 {
  width: calc(125px);
  overflow: hidden;
  clip-path: inset(0 80% 0 0); /* Hide right half */
}

.stars-grey-4-5 {
  transform: scaleX(-1);
  width: 125px;
  overflow: hidden;
  clip-path: inset(0 90% 0 0); /* Hide right half */
}

.stars-grey-4 {
  transform: scaleX(-1);
  width: 125px;
  overflow: hidden;
  clip-path: inset(0 80% 0 0); /* Hide right half */
}

.stars-grey-3-5 {
  transform: scaleX(-1);
  width: 125px;
  overflow: hidden;
  clip-path: inset(0 70% 0 0); /* Hide right half */
}

.stars-grey-3 {
  transform: scaleX(-1);
  width: 125px;
  overflow: hidden;
  clip-path: inset(0 60% 0 0); /* Hide right half */
}

.stars-grey-2-5 {
  transform: scaleX(-1);
  width: 125px;
  overflow: hidden;
  clip-path: inset(0 50% 0 0); /* Hide right half */
}

.stars-grey-2 {
  transform: scaleX(-1);
  width: 125px;
  overflow: hidden;
  clip-path: inset(0 40% 0 0); /* Hide right half */
}

.stars-grey-1-5 {
  transform: scaleX(-1);
  width: 125px;
  overflow: hidden;
  clip-path: inset(0 30% 0 0); /* Hide right half */
}

.stars-grey-1 {
  transform: scaleX(-1);
  width: 125px;
  overflow: hidden;
  clip-path: inset(0 20% 0 0); /* Hide right half */
}

.half-star {
  width: 25px;
  overflow: hidden;
  clip-path: inset(0 50% 0 0); /* Hide right half */
}

/**
  * HOME INTELLIGENCE
  */

#message h3 {
  @apply font-body text-lg font-bold;
}
#message ol {
  @apply flex flex-col gap-5;
}

#message a {
  @apply underline visited:text-red-900 hover:no-underline;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: translateY(-15%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animate-dropdown {
  animation: dropdown 0.4s ease-out forwards;
}

.button-animate {
  @apply border-2 border-black bg-black text-white transition-all duration-300 hover:border-gray-700 hover:bg-gray-700 hover:text-white;
}

.alt-button-animate {
  @apply border-2 border-black bg-transparent text-black transition-all duration-300 hover:bg-black hover:text-white;
}
